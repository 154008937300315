import React from 'react';
import { Col, Row } from 'react-bootstrap';
import GenericStats from 'components/mb-stats/generic/Generic';
import Users from 'components/mb-users/user-list/Users';
import Bounties from 'components/mb-bounties/bounty-list/Bounties';

const Index = () => {
  return (
    <>
      <Row className="g-3 mb-3">
        <Col sm={12}>
          <GenericStats />
        </Col>
        <Col sm={12}>
          <Users />
        </Col>
        <Col sm={12}>
          <Bounties />
        </Col>
      </Row>
    </>
  );
};

export default Index;
