import React from 'react';
import { Row, Col } from 'react-bootstrap';

import BalancePurchases from 'components/mb-stats/generic/counter/BalancePurchases';
import Executions from 'components/mb-stats/generic/counter-with-ratios/Executions';
import AgentBuilderJobs from 'components/mb-stats/generic/counter-with-ratios/AgentBuilderJobs';
import AgentExecutorCronJobs from 'components/mb-stats/generic/counter-with-ratios/AgentExecutorCronJobs';

const Generic = () => {
  return (
    <Row className="g-3 mb-0">
      <Col key={'Executions'} sm={12} md={6}>
        <Executions />
      </Col>
      <Col key={'AgentBuilderJobs'} sm={12} md={6}>
        <AgentBuilderJobs />
      </Col>
      <Col key={'AgentExecutorCronJobs'} sm={12} md={6}>
        <AgentExecutorCronJobs />
      </Col>
      <Col key={'BalancePurchases'} sm={12} md={6}>
        <BalancePurchases />
      </Col>
    </Row>
  );
};

export default Generic;
