import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Error404 from 'components/common/Error404';
import Panel from 'components/mb-bounties/bounty-details/panel/Panel';
import { getBounty, getBountyApplications } from 'helpers/requests/mb-bounties';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import BountyApplication from 'components/mb-bounties/bounty-details/BountyApplication';
import { Row } from 'react-bootstrap';

const Bounty = () => {
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [bounty, setBounty] = useState({});
  const [bountyApplications, setBountyApplications] = useState([]);
  const { id } = useParams();

  let handleGetBounty = async () => {
    const r = await getBounty(id);
    if (r.success) {
      setBounty(r.response.data);
      setLoading(false);
    } else {
      if (r.response.status === 404) {
        setNotFound(true);
      } else {
        handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
      }
    }
  };

  let handleGetBountyApplications = async () => {
    const r = await getBountyApplications(id);
    if (r.success) {
      setBountyApplications(r.response.data);
    } else {
      handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
    }
  };

  useEffect(() => {
    handleGetBounty();
    handleGetBountyApplications();
  }, []);

  if (notFound) {
    return <Error404 />;
  }

  return (
    <>
      <Panel loading={loading} bounty={bounty} />
      <Row className="mb-3">
        {bountyApplications.map(application => (
          <BountyApplication
            key={application.id}
            bountyApplication={application}
          />
        ))}
      </Row>
    </>
  );
};

export default Bounty;
