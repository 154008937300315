import React, { useState, useEffect } from 'react';
import InfoCard from 'components/mb-stats/generic/counter-with-ratios/InfoCard';
import { getAgentExecutorCronJobStatusMetrics } from 'helpers/requests/mb-stats';

const AgentExecutorCronJobs = () => {
  const [running, setRunning] = useState(0);
  const [suspended, setSuspended] = useState(0);

  const handleGetAgentExecutorCronJobStatusMetrics = async () => {
    const r = await getAgentExecutorCronJobStatusMetrics();
    if (r.success) {
      const totals = r.response.data.reduce(
        (acc, item) => {
          acc.running += item.running;
          acc.suspended += item.suspended;
          return acc;
        },
        { running: 0, suspended: 0 }
      );
      setRunning(totals.running);
      setSuspended(totals.suspended);
    }
  };

  useEffect(() => {
    handleGetAgentExecutorCronJobStatusMetrics();
  }, []);

  const data = [
    { value: running, name: 'Running', color: 'success' },
    { value: suspended, name: 'Suspended', color: 'warning' }
  ];

  return <InfoCard title={'Cron Jobs'} data={data} radius={['100%', '85%']} />;
};

export default AgentExecutorCronJobs;
