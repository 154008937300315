import { ax } from 'helpers/requests/axios';
import { apiPaths } from 'config';

export async function getAgent(id) {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(apiPaths.appMbAgents.agents.detail(id));
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getCronJobs(agentId) {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(
      apiPaths.appMbAgents.agentExecutorCronJobs.list,
      { params: { agent_id: agentId } }
    );
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getExecutions(agentId) {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(apiPaths.appMbAgents.executions.list, {
      params: { agent_id: agentId }
    });
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getBuild(id) {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(apiPaths.appMbAgents.builds.detail(id));
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}

export async function getLatestAgentBuilderJob(agentId) {
  const EXPECTED_STATUS_CODE = 200;
  try {
    const response = await ax.get(
      apiPaths.appMbAgents.agentBuilderJobs.latest,
      {
        params: { agent_id: agentId }
      }
    );
    return {
      success: response.status === EXPECTED_STATUS_CODE,
      response: response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  } catch (error) {
    return {
      success: false,
      response: error.response,
      expectedStatusCode: EXPECTED_STATUS_CODE
    };
  }
}
