import React from 'react';
import { Card } from 'react-bootstrap';
import PanelHeaderAuthenticated from 'components/mb-bounties/bounty-details/panel/PanelHeaderAuthenticated';
import PanelBody from 'components/mb-bounties/bounty-details/panel/PanelBody';

const Panel = ({ loading, bounty }) => {
  return (
    <Card className="mb-3">
      <PanelHeaderAuthenticated loading={loading} bounty={bounty} />
      <PanelBody loading={loading} bounty={bounty} />
    </Card>
  );
};

export default Panel;
