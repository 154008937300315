import React, { useEffect, useState } from 'react';
import { getBounties } from 'helpers/requests/mb-bounties';
import { Card, Row, Col } from 'react-bootstrap';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import Loading from 'components/mb-bounties/bounty-list/Loading';
import BountyCard from 'components/mb-bounties/bounty-list/BountyCard';

const Bounties = () => {
  const [bountyList, setBountyList] = useState([]);
  const [loading, setLoading] = useState(true);

  let handleGetBounties = async () => {
    const r = await getBounties();
    if (r.success) {
      setBountyList(r.response.data);
    } else {
      handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
    }
    setLoading(false);
  };

  useEffect(() => {
    handleGetBounties();
  }, []);

  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          <Row>
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Bounties</h5>
            </Col>
          </Row>
        </Card.Header>
      </Card>
      <Row>
        {loading && <Loading />}
        {!loading && (
          <>
            {bountyList.map((bounty, index) => (
              <BountyCard key={index} bounty={bounty} />
            ))}
          </>
        )}
      </Row>
    </>
  );
};

export default Bounties;
