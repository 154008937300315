import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';

const BountyApplication = ({ bountyApplication }) => {
  console.log(bountyApplication);
  return (
    <Col xl={6} className="mb-3">
      <Card className="h-100">
        <Card.Header className="pb-0">
          <Row>
            <Col xs={12}>
              <Card.Title>
                <h5>{bountyApplication.user.email}</h5>
              </Card.Title>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="py-1 pb-3">
          <Card.Text className="text-muted">
            <small className="text-muted">
              User ID: {bountyApplication.user.id}
            </small>
          </Card.Text>

          <Card.Text className="text-muted">
            <small>Contact Info: {bountyApplication.contact_information}</small>
          </Card.Text>

          <Card.Text className="text-muted">
            <small>
              Proposed Amount in Tokens:{' '}
              {bountyApplication.proposed_amount_in_tokens}
            </small>
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default BountyApplication;
