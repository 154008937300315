import React, { useEffect, useRef } from 'react';
import { Card, Dropdown, Placeholder, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { handleUnexpectedStatusCode } from 'helpers/errors';
import SoftBadge from 'components/common/SoftBadge';
import { approveBounty, rejectBounty } from 'helpers/requests/mb-bounties';

const PanelHeaderAuthenticated = ({ loading, bounty }) => {
  const isMounted = useRef(true);

  const handleApproveBounty = async () => {
    if (!window.confirm('Are you sure you want to approve this bounty?')) {
      return;
    }
    const r = await approveBounty(bounty.id);
    if (r.success) {
      toast.success('Bounty approved.');
    } else {
      handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
    }
  };

  const handleRejectBounty = async () => {
    if (!window.confirm('Are you sure you want to reject this bounty?')) {
      return;
    }
    const r = await rejectBounty(bounty.id);
    if (r.success) {
      toast.success('Bounty rejected.');
    } else {
      handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
    }
  };

  const getArchivedMessage = () => {
    if (bounty.archived) {
      return 'Archived';
    } else if (bounty.archived === false) {
      return 'Open';
    } else {
      return 'Unknown';
    }
  };

  const getApprovedMessage = () => {
    if (bounty.approved) {
      return 'Approved';
    } else if (bounty.approved === null) {
      return 'Pending';
    } else if (bounty.approved === false) {
      return 'Rejected';
    } else {
      return 'Unknown';
    }
  };

  const getApprovedColor = () => {
    if (bounty.approved) {
      return 'success';
    } else if (bounty.approved === null) {
      return 'warning';
    } else if (bounty.approved === false) {
      return 'danger';
    } else {
      return 'warning';
    }
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  if (loading) {
    return (
      <Card.Header>
        <Placeholder as={Card.Text} animation="glow">
          <Placeholder xs={1} />
        </Placeholder>
      </Card.Header>
    );
  }

  return (
    <Card.Header>
      <Row className="d-flex">
        <Col
          xs={6}
          className="d-flex justify-content-between align-items-center"
        >
          <h5 className="fs-0 mb-0 d-flex align-items-center text-success">
            {bounty.amount_in_tokens} SNAI
          </h5>
        </Col>
        <Col xs={6} className="d-flex align-items-center justify-content-end">
          <SoftBadge bg="info" className="mx-2">
            {getArchivedMessage()}
          </SoftBadge>
          <SoftBadge bg={getApprovedColor()} className="mx-2">
            {getApprovedMessage()}
          </SoftBadge>
          <>
            <Dropdown align="end" className="btn-reveal-trigger d-inline-block">
              <Dropdown.Toggle split variant="falcon-primary" size="sm">
                <FontAwesomeIcon icon="ellipsis-h" className="fs--2" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="border py-0">
                <div className="py-2">
                  <Dropdown.Item
                    className="text-primary"
                    as={Link}
                    onClick={handleApproveBounty}
                  >
                    Approve bounty
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="text-primary"
                    as={Link}
                    onClick={handleRejectBounty}
                  >
                    Reject bounty
                  </Dropdown.Item>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </>
        </Col>
      </Row>
    </Card.Header>
  );
};

export default PanelHeaderAuthenticated;
