import React from 'react';
import { Col, Row, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { appPaths } from 'config';
import SoftBadge from 'components/common/SoftBadge';

const BountyCard = ({ bounty }) => {
  const viewBountyUrl = appPaths.bounties.detail.replace(':id', bounty.id);

  const getArchivedMessage = () => {
    if (bounty.archived) {
      return 'Archived';
    } else if (bounty.archived === false) {
      return 'Open';
    } else {
      return 'Unknown';
    }
  };

  const getApprovedMessage = () => {
    if (bounty.approved) {
      return 'Approved';
    } else if (bounty.approved === null) {
      return 'Pending';
    } else if (bounty.approved === false) {
      return 'Rejected';
    } else {
      return 'Unknown';
    }
  };

  const getApprovedColor = () => {
    if (bounty.approved) {
      return 'success';
    } else if (bounty.approved === null) {
      return 'warning';
    } else if (bounty.approved === false) {
      return 'danger';
    } else {
      return 'warning';
    }
  };

  return (
    <Col xl={6} className="mb-3">
      <Card className="h-100">
        <Card.Header>
          <Row>
            <Col xs={6}>
              <Card.Title>
                <h5 className="mb-3 text-success">
                  {bounty.amount_in_tokens} SNAI
                </h5>
                <Button
                  variant="link text-dark"
                  className="p-0 stretched-link"
                  as={Link}
                  to={viewBountyUrl}
                >
                  {bounty.name}
                </Button>
              </Card.Title>
            </Col>
            <Col
              xs={6}
              className="d-flex align-items-center justify-content-end"
            >
              <SoftBadge bg="info" className="mx-2">
                {getArchivedMessage()}
              </SoftBadge>
              <SoftBadge bg={getApprovedColor()} className="mx-2">
                {getApprovedMessage()}
              </SoftBadge>
            </Col>
          </Row>
        </Card.Header>
      </Card>
    </Col>
  );
};

export default BountyCard;
